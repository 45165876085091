var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('el-row', [_c('el-button', {
    attrs: {
      "icon": "el-icon-arrow-left"
    },
    on: {
      "click": function ($event) {
        return _vm.$router.go(-1);
      }
    }
  }, [_vm._v(" Go back ")])], 1), _c('el-row', [_c('el-col', [_c('el-card', {
    attrs: {
      "shadow": "never"
    }
  }, [_c('el-form', {
    ref: "specForm",
    attrs: {
      "model": _vm.newSpecForm.product_meta,
      "rules": _vm.specFormRule
    }
  }, [_c('el-form-item', {
    staticClass: "mb-5",
    attrs: {
      "label": "Trade Product",
      "prop": "product_uuid"
    }
  }, [_c('el-select', {
    staticStyle: {
      "width": "100%"
    },
    attrs: {
      "filterable": "",
      "remote": "",
      "reserve-keyword": "",
      "clearable": "",
      "placeholder": "Enter a keyword to search trade products",
      "remote-method": _vm.fetchTradeProducts,
      "loading": _vm.isTradeProductLoading
    },
    model: {
      value: _vm.newSpecForm.product_meta.product_uuid,
      callback: function ($$v) {
        _vm.$set(_vm.newSpecForm.product_meta, "product_uuid", $$v);
      },
      expression: "newSpecForm.product_meta.product_uuid"
    }
  }, _vm._l(_vm.tradeProducts, function (item) {
    return _c('el-option', {
      key: item.id,
      attrs: {
        "label": item.name,
        "value": item.uuid
      }
    });
  }), 1)], 1), _c('el-divider', {
    attrs: {
      "content-position": "left"
    }
  }, [_vm._v("Product specification form")]), _c('el-skeleton', {
    attrs: {
      "rows": 10,
      "animated": "",
      "loading": _vm.isProductSpecLoading,
      "throttle": 500
    }
  }, [_c('el-collapse', {
    attrs: {
      "accordion": ""
    },
    model: {
      value: _vm.activeCategories,
      callback: function ($$v) {
        _vm.activeCategories = $$v;
      },
      expression: "activeCategories"
    }
  }, _vm._l(_vm.newSpecForm.category, function (category, index) {
    return _c('el-collapse-item', {
      key: index,
      attrs: {
        "title": category.category,
        "name": category.id
      }
    }, [_c('template', {
      slot: "title"
    }, [_vm._v(" " + _vm._s(category.category) + " "), _c('el-tag', {
      staticClass: "ml-4",
      attrs: {
        "type": "info"
      }
    }, [_vm._v(" " + _vm._s(_vm.getAnsweredCategoryCount(category)) + " of " + _vm._s(category.specifications.length) + " answered ")])], 1), _c('el-row', {
      attrs: {
        "gutter": 24
      }
    }, _vm._l(category.specifications, function (spec, idx) {
      return _c('el-col', {
        key: idx,
        attrs: {
          "span": 12,
          "xs": 24,
          "sm": 24,
          "md": 12
        }
      }, [_c('el-form-item', {
        attrs: {
          "label": spec.name
        }
      }, [_c('el-input', {
        attrs: {
          "placeholder": spec.description
        },
        model: {
          value: spec.answer,
          callback: function ($$v) {
            _vm.$set(spec, "answer", $$v);
          },
          expression: "spec.answer"
        }
      })], 1)], 1);
    }), 1)], 2);
  }), 1)], 1)], 1), _c('div', {
    staticClass: "mt-4"
  }, [_c('el-button', {
    on: {
      "click": function ($event) {
        return _vm.$router.go(-1);
      }
    }
  }, [_vm._v(" Cancel")]), _c('el-button', {
    attrs: {
      "type": "primary"
    },
    on: {
      "click": function ($event) {
        return _vm.submitNewProductSpec('specForm');
      }
    }
  }, [_vm._v(" Add ")])], 1)], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }