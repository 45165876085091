var render = function () {
  var _vm$defaultSpecForm, _vm$defaultSpecForm$p;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "flex align-items-center mb-5",
    staticStyle: {
      "gap": "16px"
    }
  }, [_c('el-button', {
    attrs: {
      "icon": "el-icon-arrow-left"
    },
    on: {
      "click": function ($event) {
        return _vm.$router.go(-1);
      }
    }
  }, [_vm._v(" Go back ")]), _c('h5', [_vm._v(_vm._s((_vm$defaultSpecForm = _vm.defaultSpecForm) === null || _vm$defaultSpecForm === void 0 ? void 0 : (_vm$defaultSpecForm$p = _vm$defaultSpecForm.product) === null || _vm$defaultSpecForm$p === void 0 ? void 0 : _vm$defaultSpecForm$p.name))])], 1), _c('el-card', {
    attrs: {
      "shadow": "never"
    }
  }, [_c('el-form', [_c('el-skeleton', {
    attrs: {
      "rows": 10,
      "animated": "",
      "loading": _vm.isProductSpecLoading
    }
  }, [_c('el-collapse', {
    staticClass: "mb-5",
    attrs: {
      "accordion": ""
    },
    model: {
      value: _vm.activeCategories,
      callback: function ($$v) {
        _vm.activeCategories = $$v;
      },
      expression: "activeCategories"
    }
  }, _vm._l(_vm.defaultSpecForm.categories, function (category, index) {
    return _c('el-collapse-item', {
      key: index,
      attrs: {
        "title": category.category,
        "name": index
      }
    }, [_c('template', {
      slot: "title"
    }, [_vm._v(" " + _vm._s(category.category) + " "), _c('el-tag', {
      staticClass: "ml-4",
      attrs: {
        "type": "info"
      }
    }, [_vm._v(" " + _vm._s(_vm.getAnsweredCategoryCount(category)) + " of " + _vm._s(category.specifications.length) + " answered ")])], 1), _c('el-row', {
      attrs: {
        "gutter": 24
      }
    }, _vm._l(category.specifications, function (spec, idx) {
      return _c('el-col', {
        key: idx,
        attrs: {
          "span": 12,
          "xs": 24,
          "sm": 24,
          "md": 12
        }
      }, [_c('el-form-item', {
        attrs: {
          "label": spec.name
        }
      }, [_c('el-input', {
        attrs: {
          "placeholder": spec.description
        },
        on: {
          "change": function ($event) {
            _vm.isChangesMade = true;
          }
        },
        model: {
          value: spec.answer,
          callback: function ($$v) {
            _vm.$set(spec, "answer", $$v);
          },
          expression: "spec.answer"
        }
      })], 1)], 1);
    }), 1)], 2);
  }), 1), _c('el-row', [_c('el-button', {
    attrs: {
      "plain": ""
    },
    on: {
      "click": _vm.handleCancelChanges
    }
  }, [_vm._v(" Cancel changes ")]), _c('el-button', {
    attrs: {
      "type": "primary"
    },
    on: {
      "click": function ($event) {
        return _vm.updateSpecDetail();
      }
    }
  }, [_vm._v(" Update ")])], 1)], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }